











import { Component, Vue } from 'vue-property-decorator'

// components
import CoursesList from '@/components/views/store/List.vue'
import CoursesMy from '@/components/views/courses/My.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'

@Component({
  components: {
    LinkTabs,
    CoursesList,
    CoursesMy,
  },
})
export default class Store extends Vue {
  private tabs = [
    {
      name: 'Мои курсы',
      to: { name: 'master.store.my' },
    },
    {
      name: 'Магазин',
      to: { name: 'master.store.shop' },
    },
  ]

  private mounted() {
    if (this.$route.name === 'master.store') {
      this.$router.replace({ name: 'master.store.my' })
    }
  }
}
